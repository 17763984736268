import React from 'react';
import PropTypes from 'prop-types';
import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';
import BrandedBadge from '../../../01_atoms/BrandedBadge';

import styles from './index.module.scss';

const BBOtherWaysToDonate = ({ heading = '', items, behaviorSettings, uuid = null }) => {
  const withLabel = items.some((item) => item.badge);
  const classes = [
    'bb',
    'bb-other-ways-to-donate',
    styles['bb-other-ways-to-donate'],
    ...(withLabel ? [styles['bb-other-ways-to-donate--with-label']] : []),
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <div className={classes.join(' ')} id={uuid}>
      <div className="container">
        {heading && <h2>{heading}</h2>}
        <div className={styles['bb-other-ways-to-donate__wrapper']}>
          {items.map((item, index) => (
            <Link key={item.link.label} {...item.link.nextLink}>
              <a
                href={item.link.nextLink.url}
                data-title={item.link.nextLink.title ?? item.link.label}
                data-category="Other ways to donate block"
                data-position={index + 1}
                data-badge={item.badge}
                className={styles['bb-other-ways-to-donate__link']}
              >
                {item.iconSrc && (
                  <img
                    src={item.iconSrc}
                    width={48}
                    height={48}
                    alt=""
                    className={styles['bb-other-ways-to-donate__icon']}
                  />
                )}
                <div className={styles['bb-other-ways-to-donate__label']}>{item.link.label}</div>
                {item.badge && (
                  <BrandedBadge
                    label={item.badge}
                    className={styles['bb-other-ways-to-donate__badge']}
                  />
                )}
              </a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

BBOtherWaysToDonate.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      iconSrc: PropTypes.string.isRequired,
      link: PropTypes.shape({
        label: PropTypes.string.isRequired,
        nextLink: PropTypes.shape(linkPropTypes).isRequired,
      }).isRequired,
      badge: PropTypes.string,
    }),
  ).isRequired,
  behaviorSettings: behaviorSettingsProps.isRequired,
  uuid: PropTypes.string,
};

export default BBOtherWaysToDonate;
